/** Copyright 2023 SmartMove */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { FieldArray } from 'redux-form' // ES6

import FieldInput from '../common/FieldInput';
import SelectInput from '../common/SelectInput';
import FieldArrayInput from "../common/FieldArrayInput";

const bookingStatusArray = [
        {value: 'Available', label:'Available'},
        {value: 'Offered', label: 'Offered'},
        {value: 'Booked', label: 'Booked'},
        {value: 'Completed', label: 'Completed'},
        {value: 'NoShow', label: 'NoShow'},
        {value: 'Cancelled', label: 'Cancelled'}];

/**
 * This container is to allow user to edit booking details.
 * */

export const BookingForm = ({ handleSubmit, submitting, heading, handleSave, initialFleet}) => {

    return (
        <form onSubmit={handleSubmit(handleSave)}>
            <br></br>
            <h4>{heading}</h4>
            <p>{initialFleet}</p>

            {/*<div className="row">*/}
            {/*    <div className="col-md-6">*/}
            {/*        <Field*/}
            {/*            type="text"*/}
            {/*            name="BookingID"*/}
            {/*            label="Booking Id"*/}
            {/*            component={FieldInput}*/}
            {/*            disable={true}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="row">
                <div className="col-md-6">
                    <Field
                        type="text"
                        name="clientName"
                        label="Client Name"
                        component={FieldInput}
                        disable={true}
                    />
                </div>
                <div className="col-md-6">
                    <Field
                        type="text"
                        name="phoneNumber"
                        label="Client Phone"
                        component={FieldInput}
                        disable={true}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <Field
                        type="text"
                        name="pickupTime"
                        label="Pickup Date/Time"
                        placeholder="YYYY-MM-DD HH:MM:SS"
                        component={FieldInput}
                        disable={true}
                    />
                </div>
                <div className="col-md-6">
                    <Field
                        type="text"
                        name="pickup.text"
                        label="Pick up"
                        component={FieldInput}
                        disable={true}
                    />

                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <Field
                        type="text"
                        name="status"
                        label="Booking Status"
                        options={bookingStatusArray}
                        component={SelectInput}
                    />
                </div>
                <div className="col-md-6">
                    <FieldArray
                        name="destinations"
                        component={FieldArrayInput}
                    />
                </div>
            </div>

            <div className="text-right">
                <button type="submit" disabled={submitting} className="btn btn-primary"> Save</button>
            </div>
            <br></br>
        </form>
    );
};

const validate = values => {

    const errors = {};;

    // for new bookings
    if(values && _.isEmpty(values)) {
        values = {
            responseType: "",
            bookings:{
                bookingNotes: "",
                entryType: "DISPATCH_CLIENT", // TODO: change to different entry type? like AWS_CLIENT
                bookingPayments:[""],
                clientName: "gstest",
                fixedFare: false,
                attributeList:[ ],
                destinations:["","",""],
                pickup:{
                    gpsCoordinates:{
                        valid:false,
                        latitude: "",
                        longitude: ""
                    },
                    text:""
                },
                selectedVehicle: 46,
                pickupTimeString: "",
                selectedDriver: "",
                bookingStatusDetail: {
                    fleetId: 37,
                    serverId: 1,
                    bookingId: 58559,
                    status: "Available"
                },
                version: 3,
                bookingReference: "",
                driverInfo: "" ,
                accountId: 0,
                pickupTime: "",
                phoneNumber: "",
                autoDispatch: true,
                prebooking: true,
                attributes:[4,3],
                EmailAddresses:[],
                operatorId:122395
            },
            errorCode:0,
            responseTime: 1670978917239,
            BookingId:58559,
            fleetId:37,
            message:"",
            pendingBookingId:0,
            responseCode:0
        };
    }

    // to minimise undefined errors
    errors['bookings'] = {pickup: ''};
    errors['bookings']['bookingStatusDetail'] = { placeholder: ''};
    errors['bookings']['pickup'] = { placeholder: ''};

    if (!values.BookingId) {
        errors.BookingId = '*Required';
    }

    if (!values.status) {
        errors['bookings']['bookingStatusDetail']['status'] = '*Required';
    }

    if (!values.clientName) {
        errors['bookings']['clientName'] = '*Required';
    }

    if (!values.phoneNumber ) {
        errors['bookings']['phoneNumber'] = '*Required';
    }

    if (!values.pickup.text) {
        errors['bookings']['pickup']['text'] = '*Required';
    }

    if (!values.pickupTime) {
        errors['bookings']['pickupTimeString'] = '*Required';
    }

    if (!values.destinations) {
        errors['bookings']['destinations'] = '*Required';
    }
    return errors;
};

export default reduxForm({
    form: 'BookingForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate
})(BookingForm);
