import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import bookingsReducer from './bookingsReducer';
import selectedBookingReducer from './selectedBookingReducer';
import apiReducer from './apiReducer';

export default combineReducers({
    bookingsReducer,
    selectedBookingReducer,
    apiReducer,
    form: formReducer    
});


