/** Copyright 2023 SmartMove */
import React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import * as bookingAction from '../../action/BookingAction';

import BookingForm from "./BookingForm";
import toastr from "toastr";

/**
 * This class is a test class to see SAVE BOOKING API is working.
 * Will be removed or deprecated in future.
 *
 * */
class NewBookingForm extends React.Component {

    constructor() {
        super();

        this.handleSave = this.handleSave.bind(this);
        this.handleReturn = this.handleReturn.bind(this);
    }


    handleReturn() {
        this.props.history.push('/');
    }

    handleSave(values) {
        const newBooking = {
            BookingId: values.BookingId,
            fleetId: values.fleetId,
            pendingBookingId: values.pendingBookingId,
            errorCode: values.errorCode,
            responseType: values.responseType,
            responseCode: values.responseCode,
            responseTime: values.responseTime,
            message: values.message,
            bookings : values.bookings // needs to update each?
        };

        this.props.action.saveBookingAction(newBooking, false)
            .then((res) => {
                if(res){
                    toastr.remove();
                }
                toastr.success('Booking created');
            }).catch(error => {
            toastr.error(error);
        })
    }



    render() {
        const fleetId = this.props.match.params.fleetId;
        const values = {
            responseType: "retrieveBooking",
            bookings: {
                bookingNotes: "",
                entryType: "DISPATCH_CLIENT", // TODO: change to different entry type? like AWS_CLIENT
                bookingPayments:[],
                clientName: "TESTING",
                fixedFare: false,
                attributeList:[ ],
                destinations: ["Adelaide airport"],
                pickup:{
                    gpsCoordinates:{
                        valid:true,
                        latitude: "-34.89216613769531",
                        longitude: "138.5751953125"
                    },
                    text:"1 SAINT JAMES BOULEVARD, BROMPTON"
                },
                selectedVehicle: 46,
                pickupTimeString:  "2022-12-05 11:49:40",
                selectedDriver: "100595",
                bookingStatusDetail: {
                    fleetId: fleetId,
                    serverId: 1,
                    bookingId: 53001,
                    status: "Available"
                },
                version: 3,
                bookingReference: "",
                driverInfo: "" ,
                accountId: 0,
                pickupTime:  "1670201380000",
                phoneNumber: "0411955508",
                autoDispatch: true,
                prebooking: true,
                attributes:[],
                EmailAddresses:[[]],
                operatorId:"122395"
            },
            errorCode:0,
            responseTime: 1670978917239,
            BookingId:"53001",
            fleetId:fleetId,
            message:"",
            pendingBookingId:"0",
            responseCode:0
        };



        return (
            <div className="container">
                <div className="row mt-3">
                    <button
                        type="button"
                        className="btn btn-info btn-xs"
                        onClick={this.handleReturn}
                    >
                        <i className="fa fa-home" aria-hidden="true"/> Back to main
                    </button>
                    <p style={{paddingTop: 20}}>
                        This page is to test creating a new booking through REST API.
                        Will be deprecated later.
                    </p>
                </div>

                <BookingForm
                    heading={`ADD`}
                    handleSave={this.handleSave}
                    initialValues={values}
                />
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => ({
   action: bindActionCreators({...bookingAction }, dispatch)
});

export default connect( mapDispatchToProps)(NewBookingForm);
