/** Copyright 2023 SmartMove */
import React from 'react';
import BookingApi from "../../api/BookingApi";
import Auth from "../../auth/auth";
import toastr from "toastr";

/**
 * This container is to have entering fields (fleet ID and operator ID) to let users to access the booking view
 * Once fleet ID & operator ID entered, show filtered data
 * */
export class LandingContainer extends React.Component {

    constructor() {
        super();
        this.state = {serverId: '1', fleetId: '', operatorId: ''};
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        // Remove any location history to prevent user have wrong fleet's access
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function(event) {
            window.history.pushState(null, document.title, window.location.href);
        });

        this.checkTitle();
    }

    checkTitle() {
        document.title = 'SmartMove Emergency Failover Server';

        const fleetTitle = document.getElementsByClassName('fleetTitle');
        const hasFleetTitle = fleetTitle[0].childNodes.length == 0 ? false: true;

        if(hasFleetTitle) {
            fleetTitle[0].removeChild(fleetTitle[0].firstChild);
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name] : event.target.value
        });
    }

    handleSubmit(event) {
        // const [cookies, setCookies, removeCookie] = useCookies();
        const { serverId, fleetId, operatorId } = this.state;
        event.preventDefault();

        Auth.login(fleetId, operatorId)
           .then((token) => {
               if (token && token.length > 0) {
                   console.log('Token: ' + JSON.stringify(token));
                   // setCookies('token', token);
                   this.props.history.push(`/bookings`);
               } else {
                   toastr.error('Error logging in. Please try again');
               }
           }).catch(error => {
            console.log('Login error: ' + JSON.stringify(error));
        });

        // login({fleetId, operatorId});

        // if (!(fleetId && operatorId))  {
        //     toastr.warning('Please enter the fleet number or operator number');
        // } else {
        //     this.setState({[event.target.name] : event.target.value});
        //     // pass both fleet Id and operator Id using props
        //     this.props.history.push(`/bookings2?fleetId=${fleetId}&serverId=${serverId}`);
        // }
    }

    render() {
        const tableStyle = {
            borderCollapse: "unset",
            borderSpacing: "7px",
            display: "inline"
        }

        return (

            <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                <div className="row text-center">
                    <table style={tableStyle}>
                        <tbody>
                           <tr>
                               <th><label > Operator: &nbsp; </label></th>
                               <th>
                                   <input type="text"
                                          name ="fleetId"
                                          value = {this.state.fleetId}
                                          onChange={this.handleChange}
                                   />
                               </th>
                           </tr>
                           <tr>
                               <th><label> Password: </label></th>
                               <th>
                                   <input type="password"
                                          name ="operatorId"
                                          value = {this.state.operatorId}
                                          onChange={this.handleChange}
                                   />
                               </th>
                           </tr>
                        </tbody>
                    </table>
                </div>

                <div className="row text-center"  style={{paddingTop: 20}}>
                        <input className="p-2" type="submit" value="Submit" />
                </div>
            </form>
        );
    }
}

export default LandingContainer;
