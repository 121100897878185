import SERVER from './serverUrl';
import Cookies from "js-cookie";
const SERVER_URL = `${SERVER}/bookings`;

class BookingApi {

   static getUserBookings() {
      const token = Cookies.get('token');
      return fetch(`${SERVER}/bookings`
      ,{
            headers: {
               'Content-Type':'application/json',
               'Authorization':'Bearer ' + token,
            },
         }
      ).then(response => response.json());
   }

   static saveBooking(booking, isExisting) {
      const token = Cookies.get('token');
       return fetch(SERVER_URL, {
           method: 'post',
           headers: {
              'Content-Type': 'application/json',
              'Authorization':'Bearer ' + token,
           },
           body: JSON.stringify(booking)
       }).then(response => response.json());
    }

    static getBooking(booking) {
        // Determine whether we should be using the booking ID or the Pending Booking ID
       console.log("Booking", booking);
       console.log("BookingId", booking.BookingId);
       console.log("pendingBookingId", booking.pendingBookingId);
        let id = booking.BookingId;
        if ((!id || id === 0 || id === '0') && booking.pendingBookingId) {
           id = booking.pendingBookingId;
        }
       const token = Cookies.get('token');

        return fetch(`${SERVER_URL}/${id}?fleetId=${booking.fleetId}&serverId=${booking.serverId}`
           ,{
              headers: {
                 'Content-Type':'application/json',
                 'Authorization':'Bearer ' + token,
              },
           }
        ).then(response => response.json());
    }
}

export default BookingApi;
