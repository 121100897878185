import React from 'react';
import Select from 'react-select';

const SelectInput = ({input, name, label, options, meta: {touched, error, warning}}) => {

    return(
        <div className="form-group">
            <div htmlFor={name}><b> {label}</b></div>
            <div className="field" style={{paddingTop: '10px'}}>
                {/* Searchable dropdown menus */}
                <Select
                    {...input}
                    name={name}
                    options={options}
                    defaultValue={input}
                    defaultInputValue={input.value}
                    value={input.value.value}
                    isSearchable
                    isClearable
                    onChange = {value => value ? input.onChange(value.value) : input.onChange("")}
                    onBlur={(value) => value ? input.onBlur(value.value) : input.onBlur("")}
                />

                    {touched && ((error && <p className="text-danger">{error}</p>) || (warning && <p className="text-danger">{warning}</p>))}

            </div>
        </div>
    );
};


export default SelectInput;
