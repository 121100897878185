import SERVER from '../api/serverUrl';
import Cookies from 'js-cookie';

const SERVER_URL = `${SERVER}/bookings`;

class Auth {
   static async login(username, password) {
      console.log('Login Called');
      const options = {
         method: 'post',
         headers: {
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            "version": 1,
            "username": username,
            "password": password
         })
      }
      const response = await fetch(SERVER_URL + '/login', options);
      const data = await response.json();
      // console.log('Data: ' + JSON.stringify(data));
      const token = data.token ? data.token : '';
      Cookies.set('token', token);
      return token;
   }

   static logout = () => {
      Cookies.remove('token');
   };
}

export default Auth;
