/** Copyright 2023 SmartMove */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import toastr from 'toastr';
import * as bookingAction from '../../action/BookingAction';
import BookingList from './BookingList';
import queryString from 'query-string';
import useSearchParams from "react-router-dom";
import {clearBookingsAction, getBookingsResponse, getUserBookingsAction} from "../../action/BookingAction";
import Auth from "../../auth/auth";

/**
 * This container is to show selected fleet's booking lists.
 * */
export class BookingListContainer extends React.Component {

    fleetId;
    serverId;

    constructor(props) {
        super(props);
        this.fleetId = this.useQuery().get('fleetId');
        this.serverId = this.useQuery().get('serverId');
        if (!this.serverId) {
            this.serverId = 1;
        }
        console.log('Fleet: ' + this.serverId + '/' + this.fleetId);

        this.handleReturn = this.handleReturn.bind(this);
        this.handleNewBooking = this.handleNewBooking.bind(this);
    }

    useQuery() {
        return new URLSearchParams(window.location.search);
    }

    componentDidMount() {
        // document.title = `${this.serverId}-${this.fleetId}`;
        document.title = 'SmartMove';
        this.checkFleetTitle(this.serverId + '-' + this.fleetId);
        this.props.action.getUserBookingsAction()
            .catch(error => {
                toastr.error(error);
            });
    }

    checkFleetTitle(fleetId) {
        const fleetTitle = document.getElementsByClassName('fleetTitle');
        const hasFleetTitle = fleetTitle[0].childNodes.length == 0 ? false: true;

        if(!hasFleetTitle) {
            // const fleetTag = document.createTextNode(`Current Fleet: ${fleetId}`);
            const fleetTag = document.createTextNode('');
            fleetTitle[0].appendChild( fleetTag);
        }
    }

    handleNewBooking() {
        this.props.history.push(`/newbooking/${this.fleetId}`);
    }

    handleRefresh = () => {
        this.props.action.getUserBookingsAction()
           .catch(error => {
               toastr.error(error);
           });
    }

    handleReturn() {
        Auth.logout();
        this.props.action.clearBookingsAction();
        this.props.history.push(`/`);
    }

    render() {
        const { bookings } = this.props;
        if (!bookings) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div className="container-fluid">
                <div className="row mt-3">
                    <div style={{paddingBottom: '5px'}}>
                        <button
                           type="button"
                           className="btn btn-info btn-xs"
                           onClick={this.handleReturn} >
                            <i className="fa fa-home" aria-hidden="true"/> Logout
                        </button>
                        &nbsp; &nbsp;
                        <button
                           type="button"
                           className="btn btn-info btn-xs"
                           onClick={this.handleRefresh} >
                            <i className="fa fa-refresh" aria-hidden="true"/> Refresh
                        </button>
                    </div>


                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    className="btn btn-default btn-xs"*/}
                    {/*    onClick={this.handleNewBooking}*/}
                    {/*>*/}
                    {/*    <i className="fa fa-file" aria-hidden="true"/> New Booking*/}
                    {/*</button>*/}

                    <div className="col">
                        <BookingList bookings={bookings} />
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        bookings: state.bookingsReducer.bookings
    };
};

const mapDispatchToProps = dispatch => ({
    action: bindActionCreators({ ...bookingAction }, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(BookingListContainer);
