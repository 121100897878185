import * as ActionType from './ActionType';
import BookingApi from '../api/BookingApi';
import { ApiCallBeginAction, ApiCallErrorAction } from './ApiAction';

export const getBookingsResponse = bookings => ({
    type: ActionType.GET_BOOKINGS_RESPONSE, bookings
});

export function getUserBookingsAction() {
    return (dispatch) => {
        dispatch(ApiCallBeginAction());

        return BookingApi.getUserBookings()
           .then(response => {
              let bookings = response?.bookings ? response.bookings : [];
              console.log("Bookings", bookings);
               bookings.forEach((item) => {
                  console.log("Item", item);
                  item.uniqueId = item.serverId + '-' + item.fleetId + '-' +
                     ((item.BookingId && item.BookingId !== '0') ? item.BookingId : item.pendingBookingId);
               });
               dispatch(getBookingsResponse(bookings));
           }).catch(error => {
               throw error;
           });
    };
}

export function clearBookingsAction() {
   return (dispatch) => {
      dispatch(getBookingsResponse([]));
   }
}

export const addNewBookingResponse = () => ({
    type: ActionType.ADD_NEW_BOOKING_RESPONSE
});


export const updateExistingBookingResponse = () => ({
    type: ActionType.UPDATE_EXISTING_BOOKING_RESPONSE
});

export function saveBookingAction(bookingBeingAddedOrEdited, isExisting) {
    return function (dispatch) {

        dispatch(ApiCallBeginAction());

        return BookingApi.saveBooking(bookingBeingAddedOrEdited, isExisting)
            .then(() => {
                if(isExisting) {

                    dispatch(updateExistingBookingResponse());
                } else {
                    dispatch(addNewBookingResponse());
                }
            }).then(() => {
                dispatch(getUserBookingsAction());
            }).catch(error => {
                dispatch(ApiCallErrorAction());
                throw (error);
            });
    };
}

export const getBookingResponse = bookingFound => ({
    type: ActionType.GET_BOOKING_RESPONSE,
    booking: bookingFound
});

export function getBookingAction(booking) {
    return (dispatch) => {
        dispatch(ApiCallBeginAction());
        return BookingApi.getBooking(booking)
            .then(booking => {
                const result = getBookingResponse(booking);
                //console.log('booking: ' + JSON.stringify(result));
                dispatch(result);
                return booking;
            }).catch(error => {
                throw error;
            });
    };
}
