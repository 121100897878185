import React, { useState } from 'react';
import { Field } from 'redux-form';
import FieldInput from "./FieldInput";

const renderField = ({ input, index, meta: { touched, error } }) => {
    return (
        <div className="field" style={{paddingTop: '5px'}}>
            <input
                value={ input.value[index].text}
                className="form-control"
                disabled
            />
        </div>
    );
}

const FieldArrayInput = ({ fields, name, disable, meta: { error } }) =>
{
    return (
        <div className="field">
            <label>Destination</label>
            {fields.map((des, index) => (
                <Field
                    key={index}
                    name="destinations"
                    type="text"
                    index={ index}
                    component={renderField}
                />
            ))}
            {error && <li className="error">{error}</li>}
        </div>
    );
}

export default FieldArrayInput;
