import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';

const selectedBookingReducer = (state = initialState.selectedBookingReducer, action) => {
    switch(action.type) {
        case ActionType.GET_BOOKING_RESPONSE: {
            return {
                ...state,
                booking: _.assign(action.booking)
            };
        }
        default: { return state; }
    }
};


export default selectedBookingReducer;
