import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LandingContainer from "./booking/LandingContainer";
import NewBookingForm from "./booking/NewBookingForm";
import AppBar from "@mui/material/AppBar";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import ver from '../../package.json';
import {Container} from "@mui/material";
import {ProtectRoutes} from "../auth/protectRoutes";
import BookingListContainer2 from "./booking/BookingListContainer";

const App = () => {
    return (
        <div>
            <Box sx={{flexGrow:1}}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography
                            style={{ flex: 1, fontWeight: 'bold' }}
                            variant="h4"
                            noWrap
                            component="div" >
                            SmartMove Emergency Failover Server
                        </Typography>
                        <Typography
                            variant="h5"
                            noWrap
                            component="div"
                            className="fleetTitle">
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container sx={{ mt: 4, mb: 4 }} maxWidth="false">
            <Router>
                <div>
                    <Switch>
                       <Route exact path="/" component={LandingContainer}/>
                       <ProtectRoutes>
                          <Route exact path="/bookings" component={BookingListContainer2} />
                       </ProtectRoutes>

                        {/*GS TEST NEW BOOKING ONLY - delete later */}
                        <Route exact path="/newbooking/:fleetId" component={NewBookingForm}  />
                    </Switch>

                </div>
            </Router>
            </Container>
            <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                <div className="row text-center " style={{paddingTop: 10}}>
                    <img src={require('../img/powered_by_01_hires.png')}
                         alt='smlogo'/>
                </div>
                <Typography variant="h6" align="center" gutterBottom style={{paddingTop: '5px'}}>
                    Version: {ver.version}
                </Typography>
            </Box>
        </div>
    );
};

export default App;
