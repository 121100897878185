export default {
    bookingsReducer: {
        bookings: []
    },

    selectedBookingReducer: {
        booking: undefined
    },

    apiReducer: {
        apiCallsInProgress: 0
    }

};
