/** Copyright 2023 SmartMove */
import React from 'react';
import {connect} from 'react-redux';
import toastr from "toastr";
import {bindActionCreators} from "redux";
import * as bookingAction from '../../action/BookingAction';
import {MaterialReactTable} from 'material-react-table';
import {Box, MenuItem} from '@mui/material';

/**
 * This container is to render a BootstrapTable with booking data. When the booking is selected, row will in expanded view with allowing user to edit some fields.
 * */

class BookingList extends React.Component {
    statusList;

    constructor(props) {
        super(props);
        this.state = { };
        this.handleSave = this.handleSave.bind(this);

        this.options = {
            sortIndicator: true,
            noDataText: 'No data'
        };

        this.statusList = [
            'Available',
            'Offered',
            'Booked',
            'Completed',
            'NoShow',
            'Cancelled'
        ];

    }

    // TODO: handle save from the new table
    handleSave(values) {
        toastr.info('Saving booking ... ');
        console.log("Saving: ", values);
        const current = new Date().toISOString();
        let timeString = current.replace('T', ' ').slice(0, -5);

        const booking = {
            BookingId: values.BookingId,
            fleetId: values.fleetId,
            serverId: values.serverId,
            pendingBookingId: values.pendingBookingId,
            // errorCode: values.errorCode,
            // responseType: values.responseType,
            // responseCode: values.responseCode,
            // responseTime: values.responseTime,
            // message: values.message,
            // bookings: values.bookings
        };

        this.props.action.getBookingAction(booking)
            .then((oldBooking) => {
                //console.log('Result: ' + JSON.stringify(oldBooking));
                // Update the current booking details with the changes specified
                // oldBooking.clientName = values.clientName;
                // oldBooking.phoneNumber = values.phoneNumber;
                oldBooking.failoverStatus = values.failoverStatus;

                this.saveBooking(oldBooking);
                // toastr.success('Booking updated');
            })
            .catch(error => {
                toastr.error(error);
            });
    }

    saveBooking(booking) {
        this.props.action.saveBookingAction(booking, true)
           .then((res) => {
               if(res){
                   toastr.remove();
               }
               toastr.success('Booking updated');
           }).catch(error => {
            toastr.error(error);
        })
    }

    render() {
        const columns = [
            {
                accessorKey: 'fleetName',
                header: 'Fleet',
                filterVariant: 'text',
                enableEditing: false,
                maxSize: 100,
            }, {
                accessorKey: 'BookingId',
                header: 'Booking',
                filterVariant: 'text',
                enableEditing: false,
                maxSize: 100,
                Cell: ({ cell }) => {
                    if (cell.row.original.BookingId && cell.row.original.BookingId !== '0') {
                        return cell.row.original.BookingId;
                    } else {
                        return "[" + cell.row.original.pendingBookingId + "]";
                    }
                }
            }, {
                accessorKey: 'failoverStatus',
                header: 'Status',
                size: 100,
                filterVariant: 'multi-select',
                filterSelectOptions: this.statusList,
                muiTableBodyCellEditTextFieldProps: {
                    required: true,
                    type: 'string',
                    select: true,
                    children:  this.statusList.map((state) => (
                        <MenuItem key={state} value={state}> {state} </MenuItem>
                    )),
                    onChange: (event) => {
                        const value = event.target.value;
                        console.log("Selected status: " + JSON.stringify(value));
                    },
                },
                Cell: ({ cell }) => (
                    <Box
                        component="span">
                        {cell.getValue()? cell.getValue() : 'Available'}
                    </Box>
                ),
            }, {
                accessorKey: 'pickupTime',
                header: 'Time',
                size: 100,
                enableEditing: false,
                Cell: ({ renderedCellValue, cell }) => {
                    const diff = new Date(renderedCellValue) - new Date();
                    if (diff <= 0) {
                        return <a component="span"> ASAP </a>
                    } else if (diff < 7200000) {
                        const min = Math.round(diff / 60000) + ' mins';
                        return <a component="span"> {min} </a>
                    } else {
                        const hour = Math.round(diff / 3600000) + ' hours';
                        return <a component="span">{hour} </a>
                    }

                }
            }, {
                accessorKey: 'clientName',
                header: 'Name',
                filterVariant: 'text',
                size: 100,
                enableEditing: false
            }, {
                accessorKey: 'phoneNumber',
                header: 'Phone',
                filterVariant: 'text',
                size: 100,
                enableEditing: false

            }, {
                accessorKey: 'pickup.text',
                header: 'Pickup',
                size: 300,
                filterVariant: 'text',
                enableEditing: false,
            }, {
                accessorKey: 'destinations',
                header: 'Destination',
                size: 300,
                enableEditing: false,
                Cell: ({ cell }) => {
                    if (cell.row.original.destinations &&
                            cell.row.original.destinations.length >= 0) {
                        // more than 1 destinations
                        return cell.row.original.destinations.map(d => <li key={d.text}>{d.text}</li>)
                    } else {
                        return <a style={{color: "red"}}>No destination entered</a>
                    }
                }
            }
            // {
            //     // TODO: when dispatch client can send a request time, then show below column
            //     accessorKey: 'created',
            //     header: 'Request Time',
            // }
        ];
        return (
            <MaterialReactTable
                columns={columns}
                data={this.props.bookings}
                // enableColumnOrdering
                enablePinning
                // enableColumnResizing={true}
                // enableGlobalFilter={true}
                editingMode="cell"
                enableEditing={true}
                defaultColumn={{
                    minSize: 20, //allow columns to get smaller than default
                    maxSize: 9001, //allow columns to get larger than default
                    size: 100, //make columns wider by default
                }}
                muiTableBodyCellEditTextFieldProps={({ cell,row }) => ({
                    onBlur: (event) => {
                        const value = event.target.value;
                        console.log("Status changes to " + JSON.stringify(value));
                        // update status values
                        if (row.original.failoverStatus !== value) {
                            row.original.failoverStatus = value;
                            this.handleSave(row.original);
                        }
                    },
                })}
                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}
                initialState={{
                    showGlobalFilter: true,
                    density: 'compact',
                    pagination: { pageSize: 50 },
                }}
                positionToolbarAlertBanner="bottom"
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        bookings: state.bookingsReducer.bookings
    };
};

const mapDispatchToProps = dispatch => {
    return {action: bindActionCreators({...bookingAction }, dispatch)};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingList);
