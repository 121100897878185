import * as ActionType from '../action/ActionType';
import initialState from './initialState';
import _ from 'lodash';

const bookingsReducer = (state = initialState.bookingsReducer, action) => {
    switch(action.type) {
        case ActionType.GET_BOOKINGS_RESPONSE: {
            return {
                ...state, 
                bookings: _.assign(action.bookings)
            };
        }
        default: { return state; }

    }
};

export default bookingsReducer;
